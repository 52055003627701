<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/recordCycle' }">巡检记录</el-breadcrumb-item>
          <el-breadcrumb-item>记录详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-tips">
            <div class="box-card">
              <div class="box-card-head">
                <div>
                  <span class="alias">{{ row.alias }}</span>
                  <span>本次巡检结果：</span>
                </div>
                <div class="box-card-right">
                  <i class="icon el-icon-user"></i>
                  <span>{{ row.submitter ? row.submitter : '（无）' }}</span>
                  <span class="lines"></span>
                  <i class="icon el-icon-time"></i>
                  <span>{{ row.completeTime ? dayjs(row.completeTime).format('YYYY-MM-DD HH:mm:ss') : '（无）' }}</span>
                </div>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="任务状态：">{{ row.taskStatus==1 ? '已巡检' : row.taskStatus==2 ? '已遗漏' : '未知' }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="设备状态：">{{ row.deviceStatus==1 ? '正常' : row.deviceStatus==2 ? '异常' : '未知' }}</el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="form-title">设备信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备名称：" prop="name">{{ form.deviceBaseInfo.deviceName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物料编码：" prop="name">{{ form.deviceBaseInfo.materialsCode }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属部门：" prop="name">{{ form.deviceBaseInfo.departmentName ? form.deviceBaseInfo.departmentName : '（无）' }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="规格参数：" prop="name">{{ form.deviceBaseInfo.specification }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备类型：" prop="name">{{ form.deviceBaseInfo.deviceTypeName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="投入使用日期：" prop="name" label-width="130px">
                {{ form.deviceBaseInfo.applyTime ? dayjs(form.deviceBaseInfo.applyTime).format('YYYY-MM-DD'):'' }}
              </el-form-item>
            </el-col>
          </el-row>

          <div class="form-title">巡检信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡检代号：" prop="name">{{ form.taskInfo.taskCode }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡视点名称：" prop="name">{{ form.taskInfo.patrolName }}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设施位置：" prop="name">{{ form.taskInfo.facilityName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="时间段：" prop="name">
                {{ dateSecond(form.taskInfo.startTime) }} - {{ dateSecond(form.taskInfo.endTime) }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡检人员：" prop="name">
                <span v-for="(item, index) in form.taskInfo.staffNames" :key="index">
                  {{ form.taskInfo.staffNames.length-1 > index ? item+'、' : item }}
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡检日期：" prop="name">
                {{ form.taskInfo.startDate ? dayjs(form.taskInfo.startDate).format('YYYY-MM-DD'):'' }} ——
                {{ form.taskInfo.endDate ? dayjs(form.taskInfo.endDate).format('YYYY-MM-DD'):'' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="创建人：" prop="name">
                {{ form.taskInfo.creator ? form.taskInfo.creator: '（无）' }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="name">
                {{ form.taskInfo.createTime ? dayjs(form.taskInfo.createTime).format('YYYY-MM-DD'):'' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备状态：" prop="name">
                {{ form.deviceBaseInfo.deviceStatus==0 ? '未检测'
                : form.deviceBaseInfo.deviceStatus==1 ? '正常'
                : form.deviceBaseInfo.deviceStatus==2 ? '异常'
                : form.deviceBaseInfo.deviceStatus==3 ? '漏检'
                : form.deviceBaseInfo.deviceStatus==4 ? '维修中'
                : form.deviceBaseInfo.deviceStatus==5 ? '报废'
                : ''}}
              </el-form-item>
            </el-col>
          </el-row>
          
          <div class="form-title">巡检内容</div>
          <el-form-item
            v-for="item in form.checkContentInfo.contents"
            :key="item"
            label="检查内容："
            prop="name">
            {{ item }}
          </el-form-item>
          
          <div class="form-title">问题文字描述</div>
          <el-form-item label="" label-width="20px">{{ form.description.description ? form.description.description : '（空）' }}</el-form-item>

          <div class="form-title">巡检工作图片</div>
          <div class="img-box" v-if="form.files.fileUrls.length>0">
            <el-image 
              class="images"
              v-for="(item,index) in form.files.fileUrls"
              :key="index"
              :src="item"
              :preview-src-list="[item]">
            </el-image>
          </div>
          <div v-else>
            <el-form-item label="" label-width="20px">（空）</el-form-item>
          </div>
          <div class="bottom-btn">
            <el-row :gutter="20">
              <el-col :span="3" style="margin-top: 14px;display: flex;" v-if="form.deviceBaseInfo.deviceStatus>2">
                <el-button type="primary" size="small" @click="$router.push('/recordCycle')">取消</el-button>
              </el-col>
              <el-col :span="3" style="margin-top: 14px;display: flex;" v-if="form.deviceBaseInfo.deviceStatus==1">
                <el-button type="primary" size="small" @click="$router.push('/recordCycle')">取消</el-button>
                <el-button type="primary" @click="overhaul(3)" size="small" :disabled="roleId == 3 || roleId == 4">报废</el-button>
              </el-col>
              <el-col :span="10" style="margin-top: 14px;display: flex;" v-if="form.deviceBaseInfo.deviceStatus==2">
                <el-button type="primary" size="small" @click="$router.push('/recordCycle')">取消</el-button>
                <el-button type="primary" @click="overhaul(0)" size="small" :disabled="roleId == 3 || roleId == 4">撤销</el-button>
                <el-button type="primary" @click="overhaul(1)" size="small" :disabled="roleId == 3 || roleId == 4">检修</el-button>
                <el-button type="primary" @click="overhaul(2)" size="small" :disabled="roleId == 3 || roleId == 4">报废</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      form: {
        deviceBaseInfo: {},
        taskInfo: {},
        checkContentInfo: {},
        description: {},
        files: {}
      },
      row: {},
      loading: false,
      tableData: [],
      roleId: 0
    };
  },
  methods: {
    dayjs,
    loadData() {
      this.loading = true
      this.$ajax.post("recordTaskDetail", {
        taskDeviceId: this.row.taskDeviceId,
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s

        let m = (_s / 60) % 60

        let h = (_s / 60 - m) / 60

        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00:00:00'
      }
    },
    overhaul(e) {
      let form = {
        operation: 0,
        patrolDeviceId: this.form.deviceBaseInfo.patrolDeviceId,
        taskDeviceId: this.row.taskDeviceId
      }
      if(e==0) {
        this.updateStatus(form)
        return;
      }
      if(e==1) {
        form.operation = 1
        this.updateStatus(form)
        return;
      }
      if(e==2) {
        form.operation = 2
        this.updateStatus(form)
        return;
      }
      // 强制报废 状态为3
      if(e==3) {
        form.operation = 3
        this.updateStatus(form)
        return;
      }
    },
    // 异常记录操作 状态
    updateStatus(form) {
      this.$ajax.post("recordOperation", form).then((res) => {
        this.$router.push("/recordCycle");
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/recordCycle')
		} else {
      this.row = this.$route.params.row
    }
    this.loadData()
    this.roleId = sessionStorage.getItem('r')
  },
};
</script>

<style lang="less" scoped>
.form-box {
  padding-top: 20px;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-tips {
  margin-bottom: 20px;
  .box-card {
    background-color: #FAFAFA;
    padding: 20px 20px 0px;
    border-radius: 10px;
    .box-card-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #F4F4F4;
      .alias {
        margin-right: 10px;
        background-color: #e5f2ff;
        color: #409EFF;
        padding: 10px;
        border-radius: 5px;
      }
      .box-card-right {
        display: flex;
        align-items: center;
        .lines {
          display: inline-block;
          width: 1px;
          height: 10px;
          background: #d7d7d7;
          margin: 0 20px;
        }
        .icon {
          margin-right: 10px;
          color: #797979;
        }
      }
    }
  }
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
/deep/ .el-button {
  width:100%;
}
</style>
<style lang="less">
</style>
